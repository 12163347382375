<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Control de Asientos - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input disabled class="text-center" type="text" v-model="control_seats.code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input disabled class="text-center" type="text" v-model="control_seats.registration_date"></b-form-input>
                  </b-form-group>
                </b-col>

                
                <b-col md="6">
                  <b-form-group label="Cliente:">
                    <b-form-input disabled v-model="control_seats.name"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Usuario:">
                    <b-form-input disabled type="text" v-model="control_seats.user"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Email:">
                    <b-form-input disabled type="text" v-model="control_seats.email"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Celular:">
                    <b-form-input disabled type="text" v-model="control_seats.phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="T. Registro:">
                    <b-form-input disabled type="text" v-model="control_seats.type"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Metodo de Pago:">
                    <b-form-select disabled :options="payment_method" v-model="control_seats.payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select disabled :options="coin" v-model="control_seats.coin"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-input disabled type="text" v-model="control_seats.email"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cod. Pago:">
                    <b-form-input disabled v-model="control_seats.charge_code"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select disabled :options="state" v-model="control_seats.state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th width="5%" class="text-center">#</th>
                          <th width="10%" class="text-center">Fecha</th>
                          <th width="30%" class="text-center">Ruta</th>
                          <th width="30%" class="text-center">Unidad</th>
                          <th width="10%" class="text-center">Asiento</th>
                          <th width="10%" class="text-center">Precio</th>
                        </tr>
                      </thead>   
                      <tbody v-for="(item, it) in control_seats_detail" :key="it">
                        <tr>
                          <td class="text-center">{{it + 1}}</td>
                          <td class="text-center">{{item.date}}</td>
                          <td class="text-left">{{item.route_name }}</td>
                          <td class="text-left">{{item.bus_name +" - "+item.departure_time}}</td>
                          <td class="text-center">{{item.seating_code}}</td>
                          <td class="text-right">
                            <span v-if="control_seats.coin == 'PEN'">{{item.price_pen}} PEN</span>
                            <span v-if="control_seats.coin == 'USD'">{{item.price_usd}} USD</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                </b-col>
           
   

                <b-col md="12"></b-col>
                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button class="form-control" @click="DataPrint(control_seats.id_control_seats)" type="button" variant="info">IMPRIMIR</b-button>
                </b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/control-asientos/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
// components
import LoadingComponent from './../pages/Loading'

export default {
  name: "IncomeEdit",
  props: ["id_control_seats"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ControlSeats',
      role: 5,
      control_seats: {
          id_control_seats:'',
          id_client:'',
          id_user:'',
          code:'',
          broadcast_date:'',
          observation:'',
          coin:'',
          total:'',
          registration_date:'',
          charge_code:'',
          state:1,
          client:'',
          user:'',
      },
      control_seats_detail:[],
      charges:[],
      coin:[
        {value:'PEN', text:'Soles'},
        {value:'USD', text:'Dólares'},
      ],
      payment_method: [
        {value :"IZIPAY", text :'IZIPAY'},
        {value :"PAYPAL", text :'PAYPAL'},
        {value :"YAPE", text :'YAPE'},
        {value :"PLIN", text :'PLIN'},
        {value :"EFECTIVO", text :'EFECTIVO'},
      ],
      state:[
        {value :1, text :'Pendiente'},
        {value :2, text :'Finalizado'},
        {value :0, text :'Anulado'},
      ],
      errors: {
        id_bus: false,
        name: false,
        description: false,
        departure_time: false,
        price: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewControlSeats();
    
  },
  methods: {
    ViewControlSeats,
    DataPrint,
    Print,
    BoardingPassPDF,
  },

  computed: {
    ...mapState(["url_base"]),
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//ver usuario
function ViewControlSeats() {
  let id_control_seats = je.decrypt(this.id_control_seats);
  let me = this;
  let url = this.url_base + "control-seats/view/" + id_control_seats;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.control_seats.id_control_seats = response.data.result.control_seats.id_control_seats;
        me.control_seats.id_client = response.data.result.control_seats.id_client;
        me.control_seats.id_user = response.data.result.control_seats.id_user;
        me.control_seats.id_tour = response.data.result.control_seats.id_tour;
        me.control_seats.code = response.data.result.control_seats.code;
        me.control_seats.name = response.data.result.control_seats.name;
        me.control_seats.email = response.data.result.control_seats.email;
        me.control_seats.phone = response.data.result.control_seats.phone;
        me.control_seats.broadcast_date = response.data.result.control_seats.broadcast_date;
        me.control_seats.observation = response.data.result.control_seats.observation;
        me.control_seats.coin = response.data.result.control_seats.coin;
        me.control_seats.total = response.data.result.control_seats.total;
        me.control_seats.registration_date = response.data.result.control_seats.registration_date;
        me.control_seats.state = response.data.result.control_seats.state;
        me.control_seats.type = response.data.result.control_seats.type
        me.control_seats.user = response.data.result.control_seats.user;
        me.control_seats.payment_method = response.data.result.control_seats.payment_method;
        me.control_seats.charge_code = response.data.result.control_seats.charge_code;
        me.control_seats_detail = response.data.result.control_seats_detail;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text:"A ocurrido un error", timer: 3000,})
      me.isLoading = false;
    });
}



function DataPrint(id_control_seats) {
  let me = this;
  let url = me.url_base + "control-seats/data-print/"+id_control_seats;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      if (me.user.type_print == "PDF") {
        me.BoardingPassPDF(response.data.result.control_seat.code_hash);
      }else{
        me.Print(response.data.result);
      }
      
    } 
  })

  
}

function BoardingPassPDF(code) {

  let url = this.url_base + "boarding-pass/"+code;
  window.open(url,'_blank');

}

function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}

</script>
